<script>
  import { star } from "@/assets/icons";
  import IsometricButton from "@/components/app/course/IsometricButton.svelte";
</script>

<div class="-translate-x-2">
  <IsometricButton onClick={() => {}} icon_path={star} state="complete" />
</div>

<div class="-translate-x-10">
  <IsometricButton onClick={() => {}} icon_path={star} state="complete" />
</div>

<div class="-translate-x-6">
  <IsometricButton onClick={() => {}} icon_path={star} state="complete" />
</div>

<div class=" translate-x-2">
  <IsometricButton onClick={() => {}} icon_path={star} state="active" />
</div>
